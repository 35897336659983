<template>
  <v-container fluid>
    <Breadcrumbs />

    <v-container v-if="!firstTimeDispo && dispoList.length <= 0" fluid class="whiteBackGround">
      <first-page :img-src="imgSrc" :dialog-name.sync="firstTimeDispo" :points="points"
        :headline="headline"></first-page>
    </v-container>

    <v-row v-if="firstTimeDispo" class="pa-0 ma-0">
      <v-flex xs12 sm12 md12>
        <v-card flat tile>
          <v-card-text>
            <v-row>
              <!-- for input-->
              <v-col cols="12" sm="4">
                <v-card class="fill-height secondarygrad rounded">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5 class="secondary--text text--lighten-2 font-weight-medium">
                      Add New
                    </h5>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" style="opacity: 0.7" dark v-bind="attrs" v-on="on">
                          info_outline
                        </v-icon>
                      </template>
                      <span>Create your own disposition/call status</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field label="Name Of Status" autofocus :value="dispo.title.toUpperCase()"
                      :rules="rulesDisponame" @input="dispo.title = $event.toUpperCase()">
                    </v-text-field>
                    <v-textarea v-model="dispo.desc" label="Description" placeholder="(optional)">
                    </v-textarea>

                    <!-- isChild toggle switch -->
                    <!-- <v-switch v-model="dispo.isChild" label="Is Child" inset /> -->

                    <!-- Autocomplete for parent dispo -->
                    <!-- <v-autocomplete v-if="dispo.isChild" v-model="dispo.parentId"
                      :items="dispoList.filter((d) => !d.system)" item-text="title" item-value="id"
                      label="Select Parent Status" placeholder="Choose Parent Disposition" :rules="[
                        (v) =>
                          !!v ||
                          'Parent Dispo is required when Is Child is enabled',
                      ]" /> -->

                    <v-flex xs12 sm12 md12 style="text-align: center !important;padding-top: 20px;">
                      <div align="center">
                        <v-color-picker v-model="dispo.hex" hide-mode-switch mode="hexa"
                          class="mx-auto transparent widthCustom" style="max-width: 100% !important">
                        </v-color-picker>
                      </div>
                    </v-flex>

                    <v-flex xs12 sm12 md12 style="text-align: center !important;padding-top: 20px;">
                      <v-expansion-panels class="mb-4">
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Advance Settings
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!-- isChild toggle switch -->
                            <v-flex xs12 sm12 md12>
                              <v-switch v-model="dispo.isChild" label="Is Child" inset></v-switch>
                            </v-flex>

                            <!-- Autocomplete for Parent Disposition -->
                            <v-flex v-if="dispo.isChild" xs12 sm12 md12>
                              <!-- Autocomplete for parent dispo -->
                              <v-autocomplete v-if="dispo.isChild" v-model="dispo.parentId"
                                :items="dispoList.filter((d) => !d.system)" item-text="title" item-value="id"
                                label="Select Parent Status" placeholder="Choose Parent Disposition" :rules="[
                                  (v) =>
                                    !!v ||
                                    'Parent Dispo is required when Is Child is enabled',
                                ]" />
                            </v-flex>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-flex>
                    <v-btn fill block color="primarygrad white--text rounded" @click="add">
                      <v-icon left :size="23">add_circle</v-icon> Add
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8">
                <v-card flat class="fill-height">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5 class="secondary--text text--lighten-2 font-weight-medium">
                      Status
                    </h5>
                  </v-card-title>
                  <v-card-text>
                    <v-row v-if="preloader" style="margin: 0px">
                      <v-card-text style="text-align: center !important">
                        <v-progress-circular :width="3" color="green" indeterminate></v-progress-circular>
                      </v-card-text>
                    </v-row>
                    <v-row v-else-if="dispoHierarchy.length > 0" style="margin: 0px">
                      <!-- Loop through parent dispositions -->
                      <v-flex v-for="parent in dispoHierarchy" :key="parent._id" xs12 sm6 md6 style="padding: 5px">
                        <v-card flat class="rounded inverted">
                          <v-card-title>
                            <v-icon :color="parent.hex">fiber_manual_record</v-icon>
                            <h6 class="font-weight-medium secondary--text pl-3">
                              {{ parent.title }}
                            </h6>
                            <v-spacer></v-spacer>
                            <v-menu origin="center center" :close-on-content-click="false"
                              transition="scale-transition">
                              <template v-slot:activator="{ on }">
                                <v-btn small text fab v-on="on">
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="dispoEdit(parent)">
                                  <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="del(parent)">
                                  <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card-title>

                          <!-- Recursively display children -->
                          <v-card-text v-if="parent.children.length" style="padding-left: 20px;">
                            <div v-for="child in parent.children" :key="child._id" style="padding-left: 20px;"
                              class="custom-width">
                              <div style="padding-left: 10px;" class="flex-container custom-width">
                                <v-icon :color="child.hex">fiber_manual_record</v-icon>
                                <h6 class="text-style font-weight-medium secondary--text pl-3"
                                  style="padding-top:10px;font-size: 12px;font-weight: 400;">
                                  {{ child.title }}
                                </h6>
                                <v-spacer></v-spacer>
                                <v-menu origin="center center" :close-on-content-click="false"
                                  transition="scale-transition">
                                  <template v-slot:activator="{ on }">
                                    <v-btn small text fab v-on="on">
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item @click="dispoEdit(child)">
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="del(child)">
                                      <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>

                              <!-- Call the recursive render function for nested children -->
                              <div v-if="child.children && child.children.length" style="padding-left: 20px;">
                                <div v-for="subChild in child.children" :key="subChild._id" style="padding-left: 20px;">
                                  <div style="padding-left: 10px;" class="flex-container custom-width">
                                    <v-icon :color="subChild.hex">fiber_manual_record</v-icon>
                                    <h6 class="font-weight-medium text-style secondary--text pl-3"
                                      style="padding-top:10px;font-size: 12px;font-weight: 400;">
                                      {{ subChild.title }}
                                    </h6>
                                    <v-menu origin="center center" :close-on-content-click="false"
                                      transition="scale-transition">
                                      <template v-slot:activator="{ on }">
                                        <v-btn small text fab v-on="on">
                                          <v-icon>more_vert</v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list>
                                        <v-list-item @click="dispoEdit(subChild)">
                                          <v-list-item-title>Edit</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="del(subChild)">
                                          <v-list-item-title>Delete</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </div>
                                  <!-- Render even deeper levels recursively if there are more children -->
                                  <div v-if="
                                    subChild.children &&
                                    subChild.children.length
                                  " style="padding-left: 20px;">
                                    <!-- Include the recursive logic here for even more nested levels -->
                                    <div v-for="nestedChild in subChild.children" :key="nestedChild._id">
                                      <div style="padding-left: 10px;" class="flex-container custom-width">
                                        <v-icon :color="nestedChild.hex">fiber_manual_record</v-icon>
                                        <h6 class="text-style font-weight-medium secondary--text pl-3"
                                          style="padding-top:10px;font-size: 12px;font-weight: 400;">
                                          {{ nestedChild.title }}
                                        </h6>
                                        <v-menu origin="center center" :close-on-content-click="false"
                                          transition="scale-transition">
                                          <template v-slot:activator="{ on }">
                                            <v-btn small text fab v-on="on">
                                              <v-icon>more_vert</v-icon>
                                            </v-btn>
                                          </template>
                                          <v-list>
                                            <v-list-item @click="dispoEdit(nestedChild)">
                                              <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="del(nestedChild)">
                                              <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>
                                          </v-list>
                                        </v-menu>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>

    <v-dialog v-model="dialogDispoEdit" width="450">
      <v-card>
        <v-card-title class="primary white--text"> Edit Status </v-card-title>

        <v-card-text style="padding-top: 25px; padding-bottom: 25px">
          <!-- Title Field -->
          <v-flex xs12 sm12 md12>
            <v-text-field v-model="dispoEditedItem.title" label="Name" :rules="rulesDisponame"
              :value="dispoEditedItem.title.toUpperCase()"
              @input="dispoEditedItem.title = $event.toUpperCase()"></v-text-field>
          </v-flex>


          <!-- Description Field -->
          <v-flex xs12 sm12 md12>
            <v-textarea v-model="dispoEditedItem.desc" label="Description" placeholder="(optional)"></v-textarea>
          </v-flex>



          <!-- Color Picker -->
          <v-flex xs12 sm12 md12 style="text-align: center !important">
            <v-color-picker v-model="dispoEditedItem.hex" hide-mode-switch mode="hexa" class="mx-auto"></v-color-picker>
          </v-flex>

          <v-flex xs12 sm12 md12>

            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Advance Settings
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- isChild toggle switch -->
                  <v-flex xs12 sm12 md12>
                    <v-switch v-model="dispoEditedItem.isChild" label="Is Child" inset></v-switch>
                  </v-flex>

                  <!-- Autocomplete for Parent Disposition -->
                  <v-flex v-if="dispoEditedItem.isChild" xs12 sm12 md12>
                    <v-autocomplete v-model="dispoEditedItem.parentId" :items="dispoList.filter((d) => !d.system)"
                      item-text="title" item-value="id" label="Select Parent Status"
                      placeholder="Choose Parent Disposition" :rules="[
                        (v) =>
                          !!v || 'Parent Dispo is required when Is Child is enabled',
                      ]"></v-autocomplete>
                  </v-flex>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Dialog Actions -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialogDispoEdit = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="updateDispoName"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const _ = require("lodash");
import fb from "../js/firebase";
import validator from "../js/validator.js";
import Breadcrumbs from "../components/breadcrumbs.vue";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    Breadcrumbs,
    firstPage,
  },
  data() {
    return {
      firstTimeDispo: false,
      headline: "Custom Dispositions for Powerful Call Analysis",
      points: [
        "Customize Your Call Workflow: Create custom call statuses, dispositions, and feedback options that perfectly align with your unique sales process and telecalling team requirements.",
        "Deeper Data Insights: Gain a clearer understanding of your call performance with granular data filtering based on your custom dispositions.",
        "Actionable Reporting & Export: Generate comprehensive reports and easily export filtered data for further analysis.",
      ],
      dialogDispoEdit: false,
      dispoEditedItem: {
        title: "",
        desc: "",
        isChild: false,
        parentId: null,
      },
      preloader: false,
      dispo: {
        title: "",
        hex: "",
        desc: "",
        isChild: false, // isChild field
        parentId: null, // parentId field
      },
      rulesDisponame: [
        (v) =>
          /^[\w\s]+$/.test(v) ||
          "Maximum Fifty characters & Must Not Contain Special Characters Or Space",
      ],
    };
  },
  computed: {
    dispoList(v) {
      return this.$store.getters.dispoList;
    },
    dispoHierarchy() {
      // Recursive function to build the hierarchy
      const buildHierarchy = (parent) => {
        const children = this.dispoList
          .filter((dispo) => dispo.parentId === parent.id && !dispo.system)
          .map((child) => ({
            ...child,
            children: buildHierarchy(child), // Recursively attach children
          }));

        return children;
      };

      // Find top-level parents (dispositions with no parentId)
      const parentDispos = this.dispoList.filter(
        (dispo) => !dispo.parentId && !dispo.system
      );

      // Build the complete hierarchy for each parent
      return parentDispos.map((parent) => ({
        ...parent,
        children: buildHierarchy(parent),
      }));
    },
  },
  watch: {
    dispoList() {
      this.preloader = false;
    },
  },
  mounted() {
    console.log("dispoList ..........", this.dispoList);

    if (this.dispoList.length > 0) {
      this.firstTimeDispo = true;
    } else {
      this.firstTimeDispo = false;
    }
  },
  created() {
    console.log("dispoList ..........", this.dispoList);
  },

  methods: {
    dispoEdit(item) {
      this.dispoEditedItem = item;
      this.dialogDispoEdit = true;
    },
    // updateDispoName() {
    //   const self = this;

    //   if (this.dispoEditedItem.title == "") {
    //     this.$swal({
    //       type: "error",
    //       text: "Please add a name",
    //     });
    //   }

    //   if (
    //     this.dispoEditedItem.title.toUpperCase().trim() == "NEW" ||
    //     this.dispoEditedItem.title.toUpperCase().trim() == "SKIP" ||
    //     this.dispoEditedItem.title.toUpperCase().trim() == "DNC"
    //   ) {
    //     this.$swal({
    //       type: "error",
    //       text: "Please change the disposition name",
    //     });

    //     return;
    //   }

    //   if (
    //     !/^[\w\s]+$/.test(this.dispoEditedItem.title) ||
    //     this.dispoEditedItem.title.length > 17
    //   ) {
    //     return this.$swal({
    //       type: "warning",
    //       text:
    //         "Maximum Fifty characters & Must Not Contain Special Characters",
    //     });
    //   } else {
    //     self.$http
    //       .patch(
    //         `${this.$store.state.ENDPOINT}/dispos/${self.dispoEditedItem.id}`,
    //         self.dispoEditedItem
    //       )
    //       .then(
    //         (response) => {
    //           self.dialogDispoEdit = false;
    //           self.$store.dispatch("getDispos", {
    //             companyId: this.$store.getters.user.companyId,
    //           });
    //         },
    //         (error) => {
    //           self.$swal({
    //             type: "warning",
    //             text: "Error occured. Please try again.",
    //           });
    //         }
    //       );
    //   }
    // },

    updateDispoName() {
      // Check if the title is empty
      if (this.dispoEditedItem.title == "") {
        return this.$swal({
          type: "error",
          text: "Please add a name",
        });
      }

      // Check for restricted disposition names
      if (
        this.dispoEditedItem.title.toUpperCase().trim() == "NEW" ||
        this.dispoEditedItem.title.toUpperCase().trim() == "SKIP" ||
        this.dispoEditedItem.title.toUpperCase().trim() == "DNC"
      ) {
        return this.$swal({
          type: "error",
          text: "Please change the disposition name",
        });
      }

      // Validation for title length and special characters
      if (
        !/^[\w\s]+$/.test(this.dispoEditedItem.title) ||
        this.dispoEditedItem.title.length > 50
      ) {
        return this.$swal({
          type: "warning",
          text:
            "Maximum Fifty characters & Must Not Contain Special Characters",
        });
      }

      // Validation for parentId if isChild is true
      if (
        this.dispoEditedItem.isChild &&
        (!this.dispoEditedItem.parentId ||
          this.dispoEditedItem.parentId === null ||
          this.dispoEditedItem.parentId === undefined)
      ) {
        return this.$swal({
          type: "error",
          text: "Please select a Parent Disposition when Is Child is enabled.",
        });
      }

      // Prepare the disposition for update
      this.dispoEditedItem.title = this.dispoEditedItem.title
        .toUpperCase()
        .trim();
      this.preloader = true;

      console.log("this.dispoEditedItem", this.dispoEditedItem);

      delete this.dispoEditedItem.children;

      // Call API to update disposition
      this.$http
        .patch(
          `${this.$store.state.ENDPOINT}/dispos/${this.dispoEditedItem.id}`,
          this.dispoEditedItem
        )
        .then((response) => {
          this.dialogDispoEdit = false;
          this.$store.dispatch("getDispos", {
            companyId: this.$store.getters.user.companyId,
          });

          fb.log("update_dispo");

          return this.$swal({
            type: "success",
            text: "Disposition updated successfully.",
          });
        })
        .catch((error) => {
          this.preloader = false;
          this.$swal({
            type: "error",
            text: "Error occurred. Please try again.",
          });
        });
    },

    add() {
      // Check if the title is empty
      if (this.dispo.title == "") {
        console.log("** empty title **");
        return;
      }

      // Check for restricted disposition names
      if (
        this.dispo.title.toUpperCase().trim() == "NEW" ||
        this.dispo.title.toUpperCase().trim() == "SKIP" ||
        this.dispo.title.toUpperCase().trim() == "DNC"
      ) {
        this.$swal({
          type: "error",
          text: "Please change the disposition name",
        });
        return;
      }

      // Validation for title length and special characters
      if (!/^[\w\s]+$/.test(this.dispo.title) || this.dispo.title.length > 50) {
        return this.$swal({
          type: "warning",
          text:
            "Maximum Fifty characters & Must Not Contain Special Characters",
        });
      }

      // Validation for parentId if isChild is true
      if (
        this.dispo.isChild &&
        (!this.dispo.parentId ||
          this.dispo.parentId === null ||
          this.dispo.parentId === undefined)
      ) {
        return this.$swal({
          type: "error",
          text: "Please select a Parent Disposition when Is Child is enabled.",
        });
      }

      // Prepare the disposition
      this.dispo.title = this.dispo.title.toUpperCase().trim();
      this.preloader = true;
      this.dispo.companyId = this.$store.getters.user.companyId;

      console.log("this.dispo", this.dispo);

      // Post the data to the API
      this.$http
        .post(`${this.$store.state.ENDPOINT}/dispos`, this.dispo)
        .then((response) => {
          // Reset fields after successful creation
          this.dispo = {};
          this.dispo.title = "";
          this.dispo.hex = "";
          this.dispo.desc = "";
          this.$store.dispatch("getDispos", {
            companyId: this.$store.getters.user.companyId,
          });
          fb.log("create_dispo");

          return this.$swal({
            type: "success",
            text:
              "Please inform all app users to logout and login, to see the new changes.",
          });
        })
        .catch((e) => {
          this.preloader = false;
          this.$swal({
            type: "error",
            text: "Sorry, same status is already present",
          });
        });
    },
    del(item) {
      this.preloader = true;

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Contacts with this status will become NEW",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(`${this.$store.state.ENDPOINT}/dispos/remove`, {
                dispo: item,
              })
              .then((response) => {
                this.preloader = false;
                this.$store.dispatch("getDispos", {
                  companyId: this.$store.getters.user.companyId,
                });
                fb.log("delete_dispo");
                return this.$swal({
                  type: "success",
                  text:
                    "Please inform all app users to logout and login, to see the new changes.",
                });
              })
              .catch((e) => {
                this.preloader = false;
                console.log(e);
              });
          }
          this.preloader = false;
        });
    },
  },
};
</script>

<style scoped>
.widthCustom {
  max-width: 600px !important;
}

.v-color-picker__canvas {
  width: 600px !important;
}

h6 {
  padding: 0 !important;
  margin: 0;
}

.custom-width {
  max-width: 100%;
  /* Ensures the div respects its parent container's width */
  overflow: hidden;
  /* Prevents overflow */
  white-space: normal;
  /* Allows text wrapping */
  word-wrap: break-word;
  /* Breaks long words */
  overflow-wrap: break-word;
  /* Ensures compatibility across browsers */
}

.flex-container {
  display: flex;
  align-items: center;
  /* Align items to the top */
  justify-content: space-between;
  /* Ensures space between elements */
  flex-wrap: nowrap;
  /* Prevents wrapping, ensuring elements stay in the same row */
  width: 100%;
  /* Ensures the container takes full width */
  margin-block: 0.5rem;
}

.text-style {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0;
  /* Remove margin */
  flex-grow: 1;
  /* Allows the text to take up available space */
  max-width: calc(100% - 80px);
  /* Adjust to leave space for the icon and menu button */
}
</style>
